import { ThunkDispatch } from "@reduxjs/toolkit";
import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginBanner from "../../assets/images/login-banner.jpg";
import Logo from "../../assets/images/logo.svg";
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import ThemeInputPassword from "../../components/common/InputPassword";
import ThemeInputText from "../../components/common/InputText";
import Loader from "../../components/common/Loader";
import appConfig from "../../helpers/config";
import { baseRoutes } from "../../routes/base-routes";
import { loginAction } from "../../store/reducer/auth";
import { LoginSchema } from "../../validations/auth-schema/auth-schema";
import CryptoJS from 'crypto-js';
export const Login = () => {
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const navigate = useNavigate();
	const isLoading = useSelector((state: any) => state.auth.loading);

	// Initial Values
	const initialValues: Record<string, any> = {
		user_email: '',
		password: '',
	};

	const handleSubmit = (values: Record<string, any>, actions: any) => {
		actions.setSubmitting(false);
		const encryptionKey = appConfig.passwordEncryptionKey;
        // Encrypt
        let cipherPassword = CryptoJS.AES.encrypt(values.password, encryptionKey).toString()
        const payloadData = {email: values?.user_email, password: cipherPassword};
        dispatch(loginAction(payloadData)).then((res:any)=>{
			if(res?.payload?.statusCode == 200){
				localStorage.removeItem('stopTimer');
				localStorage.removeItem("timer")
				if(res?.payload?.data) {
					navigate(`${appConfig.basePath}/dashboard`);	
				}else {
					navigate(`${appConfig.basePath}/otp`, { state: { email: values.user_email } });
				}
			}
		})
	};

	return (
		<>
			<Loader isLoading={isLoading} />
			<Card className="border-0 overflow-auto min-vh-100 w-100 text-center z-1 py-4 px-3 p-md-5 rounded-0">
				<div className="theme-auth-header pt-2 pb-4 w-75 mx-auto">
					<Link to="/" className="d-inline-block">
						<Image src={Logo} alt="Logo" width={330} height={60} fluid />
					</Link>
				</div>
				<Row className="justify-content-center g-0 mt-md-3">
					<Col xs={12}>
						<Card className="px-4 pb-4 px-md-5 pb-md-5 text-start rounded-3 border-0 mx-auto max-w-450">
							<h1 className="fs-4 fw-semibold mb-1 mt-2 mt-md-4 py-3 py-md-4 text-center text-primary">Client Login</h1>
							<CommonFormikComponent
								validationSchema={LoginSchema}
								initialValues={initialValues}
								onSubmit={handleSubmit}
							>
								{(formikProps) => (
									<>
										<Form.Group className="mb-3">
											<ThemeInputText
												inputClassName="theme-inputtext-lg"
												controlId="user_email"
												label='Email*'
												handleBlur={formikProps.handleBlur}
												handleChange={formikProps.handleChange}
												value={formikProps.values.user_email}
												touched={formikProps.touched.user_email}
												errorsField={formikProps.errors.user_email}
											/>
										</Form.Group>
										<Form.Group className="mb-3">
											<ThemeInputPassword
												inputClassName="theme-inputtext-lg"
												toggleMask={true}
												controlId="password"
												label='Password*'
												handleBlur={formikProps.handleBlur}
												handleChange={formikProps.handleChange}
												value={formikProps.values.password}
												touched={formikProps.touched.password}
												errorsField={formikProps.errors.password}
											/>
										</Form.Group>
										<Stack
											direction="horizontal"
											gap={2}
											className="mb-2 pb-1"
										>
											<Link to={baseRoutes.forgotPassword.path} className="text-decoration-underline small">
												{baseRoutes.forgotPassword.pathName}?
											</Link>
										</Stack>
										<Button className="w-100 btn-lg mt-2 fw-normal" type="submit">
											Login
										</Button>
										<p className="mb-0 mt-4 text-center fs-15">If you are visiting the site first time, <Link to={baseRoutes.setAccount.path} className="me-auto  text-decoration-underline text-nowrap">Set Up Your Account</Link></p>
									</>
								)}
							</CommonFormikComponent>
						</Card>
					</Col>
				</Row>
				<Card.ImgOverlay className="p-0 pe-none user-select-none z-n1 rounded-0">
					<Card.Img
						src={LoginBanner}
						alt="Login Banner"
						className="w-100 h-100 object-fit-cover rounded-0"
					/>
				</Card.ImgOverlay>
			</Card>
		</>
	);
};