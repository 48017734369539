import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../validationErrors";

/**
 * OTP Schema for Validation
 * @type {*}
 */

export const OTPValidationSchema: any = yup.object().shape({
    otp: yup.string().min(6).max(6).required().required(VALIDATION_MESSAGES.OTP_REQUIRED).label("OTP")
});